
.order__page {
  margin-top: 30px;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  min-height: 600px;

  h2, h3, h4 {
    color: #61869a;
  }
  h3 {
    border-bottom: 2px solid #e6e6e6;
  }
  .panel {
    max-width: 450px;
    width: 100%;
  }
  table {
    width: 100%;
  }
  table tr {
    height: 36px;
  }
  table td {
    color: #6a6a6e;
    word-break: break-all;
  }
  table td:nth-child(1) {
    min-width: 140px;
  }

  .mb-1 {
    margin-bottom: 0.5rem;
  }
  .mb-2 {
    margin-bottom: 1rem;
  }
  .mb-3 {
    margin-bottom: 2rem;
  }
  .mb-4 {
    margin-bottom: 3rem;
  }

  .contact-form {
    display: flex;
    flex-direction: column;
    max-width: 500px;
    width: 100%;
    max-height: 500px;
    margin: 0 auto;

    input {
      border-top: none;
      border-left: none;
      border-right: none;

      &:focus {
        border-bottom: 2px solid #61869A;
      }
    }
  }

  .submit-btn {
    width: auto;
    font-size: 20px;
    padding: 10px 30px;
    border: 0;
    border-radius: .4rem;
    background-image:linear-gradient(131deg, #051f3f, #61869A, #61869A, #61869A);
    background-size: 300% 100%;
    transition: all 0.3s ease-in-out;
    color: #ffffff;
    font-family: 'Poppins', serif;
    cursor: pointer;
    text-transform: capitalize;

    &:hover {
      box-shadow: 0 0.5em 0.5em -0.4em #051f3fba;
      background-size: 100% 100%;
      transform: translateY(-0.15em);
    }
  }
}

.orders__page {
  display: block;
}

.orders__page table tr {
  text-align: left;
}
