.nav__menu {
  display: none;
}

.contact-icon {
  width: 18px;
}

.contact-icon.white {
  display: block;
}
.contact-icon.color {
  display: none;
}

.builfold-nav {
  width: 100%;
  display: flex;
  justify-content: space-between;
  padding-top: 10px;

  @media only screen and (max-width: 768px) {
    padding-top: 30px;
  }
  @media only screen and (max-width: 620px) {
    padding-top: 15px;
  }
}

.nav__logo {
  display: flex;
  align-items: center;
}

.nav__logo img {
  width: 300px;
  height: auto;
  background: #3c5b71;
  padding-right: 10px;

  @media only screen and (max-width: 620px) {
    width: 220px;
  }
}

.nav__wrapper {
  width: 71%;
  display: flex;
  justify-content: flex-end;
  align-items: center;

  @media only screen and (max-width: 768px) {
    display: none;
  }
}

.nav__contact {
  display: flex;
  align-items: center;
  cursor: pointer;
}

.nav__contact a {
  font-size: 18px;
  line-height: 120px;
  color: #fff;
  text-decoration: none;
  margin-left: 10px;
  margin-right: 70px;
}

@media only screen and (max-width: 1414px) {
  .contact-icon.white {
    display: none;
  }
  .contact-icon.color {
    display: block;
  }
  .nav__contact a {
    color: #61869A;
  }
}
