.policy__block {
  margin-bottom: 20px;

  p {
    margin: 15px 0;
    padding: 0 20px;

    @media only screen and (max-width: 420px) {
      font-size: 13px;
    }
  }
}