.top-inpt {
  display: flex;
  /* justify-content: space-around; */
}

@media only screen and (max-width: 759px) {
  .top-inpt {
    flex-direction: column;
  }
}

.label__group {
  display: flex;
  flex-direction: column;
  margin-right: 30px;
  margin-bottom: 30px;
}

.inputl-left {
  width: 30%;
}

@media only screen and (max-width: 759px) {
  .inputl-left {
    width: 100%;
  }
}

.input-right {
  width: 30%;
}

@media only screen and (max-width: 759px) {
  .input-right {
    width: 100%;
  }
}

/* thanks page */

.succefull-email {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  padding: 50px 0;
  min-height: 500px;
  color: #61869A;
}

.succefull-email h3 {
  font-size: 35px;
}

@media only screen and (max-width: 759px) {
  .succefull-email h3 {
    font-size: 22px;
  }
}

.succefull-email p {
  margin: 0;
  font-size: 22px;
}

@media only screen and (max-width: 759px) {
  .succefull-email p {
    font-size: 14px;
  }
}

.home-btn button {
  font-family: 'Poppins', serif;
  font-size: 18px;
  padding: 10px 30px;
  border: 0;
  border-radius: .4rem;
  background-image:linear-gradient(131deg, #051f3f, #61869A, #61869A, #61869A);
  background-size: 300% 100%;
  transition: all 0.3s ease-in-out;
  color: #ffffff;
  font-family: 'Poppins', serif;
  cursor: pointer;
  margin-top: 15px;
  text-transform: capitalize;
}

.home-btn button:hover {
  box-shadow: 0 0.5em 0.5em -0.4em #051f3f;
  background-size: 100% 100%;
  transform: translateY(-0.15em);
}

/* error page */
.error-email {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  padding: 50px 0;
  min-height: 500px;
  color: #61869A;
}

.error-email h3 {
  font-size: 35px;
}

.error-email p {
  margin: 0;
  font-size: 22px;
}

.home-btn button {
  font-family: 'Poppins', serif;
  font-size: 18px;
  padding: 10px 30px;
  border: 0;
  border-radius: .4rem;
  background-image:linear-gradient(131deg, #051f3f, #61869A, #61869A, #61869A);
  background-size: 300% 100%;
  transition: all 0.3s ease-in-out;
  color: #ffffff;
  font-family: 'Poppins', serif;
  cursor: pointer;
  margin-top: 45px;
  text-transform: capitalize;
}

.home-btn button:hover {
  box-shadow: 0 0.5em 0.5em -0.4em #051f3f;
  background-size: 100% 100%;
  transform: translateY(-0.15em);
}

.footer {
  position: absolute;
  bottom: 25px;
  right: 50px;
  display: flex;
  align-items: center;
}

.footer p {
  color: #ffffff;
  font-family: 'Lato', sans-serif;
  font-size: 14px;
  margin-right: 20px;
}

.footer .footer__logo {
  width: 140px;
}

@media screen and (-webkit-min-device-pixel-ratio:0) { 
  select,
  textarea,
  input {
    font-size: 16px;
  }
}