
.contact__page {
  margin-top: 30px;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  min-height: 600px;

  .contact-form {
    display: flex;
    flex-direction: column;
    max-width: 500px;
    width: 100%;
    max-height: 500px;
    margin: 0 auto;

    input {
      border-top: none;
      border-left: none;
      border-right: none;
      border-bottom: 2px solid #c8c8c8;
      margin-bottom: 50px;
      font-size: 18px;
      padding: 5px 10px;

      &:focus {
        border-bottom: 2px solid #61869A;
      }
    }
  }

  .submit-btn {
    width: auto;
    font-size: 20px;
    padding: 10px 30px;
    border: 0;
    border-radius: .4rem;
    background-image:linear-gradient(131deg, #051f3f, #61869A, #61869A, #61869A);
    background-size: 300% 100%;
    transition: all 0.3s ease-in-out;
    color: #ffffff;
    font-family: 'Poppins', serif;
    cursor: pointer;
    margin-top: 15px;
    text-transform: capitalize;

    &:hover {
      box-shadow: 0 0.5em 0.5em -0.4em #051f3fba;
      background-size: 100% 100%;
      transform: translateY(-0.15em);
    }
  }
}