.faq__title {
  margin-bottom: 20px;

 h2 {
  @media only screen and (max-width: 420px) {
    font-size: 20px;
  }
 }
}

.jss2 {
  @media only screen and (max-width: 420px) {
    font-size: 0.7375rem;
  }
  font-size: 0.7375rem;
}

.MuiPaper-elevation1 {
  box-shadow: 0px 0px 59px 0px rgba(0,0,0,0.15) !important;
}

.MuiTypography-body1 {
  @media only screen and (max-width: 420px) {
    font-size: 0.7rem;
  }
}